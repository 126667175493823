import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

// import img1slider from "../../assets/images/slide1bisgower.png";

import img1slider from "../../assets/images/slider_1.jpg";
import img2slider from "../../assets/images/slider_2.jpg";
import img3slider from "../../assets/images/slider_3.jpg";
import img4slider from "../../assets/images/slider_4.jpg";
import img5slider from "../../assets/images/slider_5.jpg";
import img6slider from "../../assets/images/slider_6.jpg";
import img7slider from "../../assets/images/slider_7.jpg";




const items = [
  {
    src: img1slider,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: img2slider,
    altText: 'Slide 2',
    caption: 'Slide 2'
  },
  {
    src: img3slider,
    altText: 'Slide 3',
    caption: 'Slide 3'
  },
 
];

const CarouselTest = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
     
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="img-fluid" style={{ width: '100%'}}/>
        
        <CarouselCaption style={{ color: '#333'}} captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
     
    );
  });

  return (
    <Container fluid={true}>
        <Row>
          <Col className="text-center mb-5">
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
    </Col>
    </Row>
    </Container>
  );
}

export default CarouselTest;