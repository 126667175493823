import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import imgCloud from "../../assets/images/img-cloud.jpg";

const Feature3 = (props) => {
  return (
    <Container fluid={true} className="backGradient">
        <Container>
        <Row>
        <Col md="6"><h1 style={{ color: '#fff', marginTop: '60px', fontSize: '55px'}}>Más Beneficios</h1>
        <h6 style={{ color: '#fff', fontSize: '19px', marginBottom: '45px'}}>Lens is the only IDE you’ll ever need to take control of your Kubernetes clusters. It is a standalone application for MacOS, Windows and Linux operating systems. Lens is built on open source and free. Download it today!</h6>

        <Link to="#contact" className="">
          <button className="btn btn-info">Contratar</button>
        </Link>

        

        </Col>
        <Col md="6" style={{ }}>
          <img src={imgCloud} className="img-fluid" />
        </Col>
      </Row>
        </Container>
      
      
     
    </Container>
  );
}

export default Feature3;