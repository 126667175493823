import React from 'react';
import './indexComingsoon.css';

import Iso from '../../assets/Iso';

import Home from './Home';
import LogoLanding from './LogoLanding';

import IsoLanding from "../../assets/images/iso1_invertido.svg";

import logolight from "../../assets/images/logo_blanco.png";
import logodark from "../../assets/images/logo_azul.png";

const IndexComingsoon = () => {
    return (
        <div className="containerFull">
        <div id="wrapper">
            <div id="contenedor">
                <div id="contenido">
                    <div className="mb-3"><img alt="Gower" src={IsoLanding} /></div>
                    {/* <div><LogoLanding height="10rem" className="miclase" color="red" /></div>
                    <div><Home height="5rem" width="3.5rem" className="miclase" color="cyan"/></div><img alt="Logotipo" src={logolight} className="mb-3" /> */}
                    <div className="mb-4"><img src={logolight} /></div>
                    <h1>PRÓXIMAMENTE</h1>
                    {/* <h5>Sistema Agíl - Moderno - Único</h5> */}
                </div>
            </div>
        </div>
        </div>
    )
}

export default IndexComingsoon;