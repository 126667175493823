import React, { useState, useEffect } from 'react';

import NavbarPage3 from '../../components/Navbar3/Navbar_Page3';
import Footer2 from '../../components/Footer2/footer2';

import './indexcomingsoon3.css';


import IsoLanding from "../../assets/images/iso1_invertido.svg";

import logolight from "../../assets/images/logo_blanco.png";
import logodark from "../../assets/images/logo_azul.png";

// import Button from 'react-bootstrap/Button';

/*
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
*/


import { Button } from 'reactstrap';
import { Modal } from 'reactstrap';


const IndexComingsoon2 = () => {

    const [navClass, setNavclass] = useState("");
    const [imglight, setImglight] = useState(true);
    const [pos, setPos] = useState(0);
    const [scroll, setScroll] = useState({});

    const [show, setShow] = useState(false);

     //const handleClose = () => setShow(false);
     //const handleShow = () => setShow(true);

     const abrir = () => {
         setShow(true);
         alert("Abrir");
         console.log(show);
     }

    /*const [scroll, setScroll] = useState({
        navClass: '',
        imglight: ''
    })*/
 
    /* componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    } */

    /* useEffect(() => {
        window.addEventListener("scroll", this.scrollNavigation, true);
        return () => {
          window.removeEventListener("scroll", this.scrollNavigation, true);
        }
     }, []) */

    // const [scrollNavigation, setScrollnavigation] = useState(true);

    const [title, setTitle] = useState("MI barra");

    const scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        console.log("scrollup", scrollup);

        console.log("pos: ", pos);

        if(scrollup > pos)
        {
            //setScroll({navClass : "darkheader"});
            setNavclass("darkheader");
            setImglight(false);
            setTitle("scroll mayor que pop");

        }
        else
        {
            //setScroll({navClass : "", imglight : true});
            setNavclass("");
            setImglight(true);
            setTitle("scroll < pos");
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        return () => {
          window.removeEventListener("scroll", scrollNavigation, true);
        }
     }, []);

    

    return (
        <React.Fragment>
            <NavbarPage3 navclass={navClass} imglight={imglight} />  
            <div className="containerFull2">
            <div id="wrapper">
            <div id="contenedor">
                <div id="contenido">
                    <div className="mb-3"><img alt="Gower" src={IsoLanding} /></div>
                    {/* <div><LogoLanding height="10rem" className="miclase" color="red" /></div>
                    <div><Home height="5rem" width="3.5rem" className="miclase" color="cyan"/></div><img alt="Logotipo" src={logolight} className="mb-3" /> */}
                    <div className="mb-4"><img src={logolight} /></div>
                    <h1>PRÓXIMAMENTE</h1>
                    <h5>Tu sistema de gestión</h5>
                    <Button className="mt-2" variant="primary" onClick={() => abrir()}>
                        Más info
                    </Button>
                </div>
            </div>
        </div>

        <div className="modal fade" show={show}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title">Modal title</h4>
                </div>
                <div className="modal-body">
                  <p>One fine body&hellip;</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>

        </div> 
            <Footer2 />
        </React.Fragment>
        
    )
}

export default IndexComingsoon2;