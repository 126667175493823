import React, { useState, useEffect, Component } from 'react';
import NavbarPage from "../../components/Navbar/Navbar_Page";
import NavbarPage2 from "../../components/Navbar2/Navbar_Page2";
import Section from "./section";
import Footer2 from '../../components/Footer2/footer2';
import Features2 from '../../components/Features2/features2';

import GetStarted2 from "../../components/Get Started2/get-started2";
import Services from '../../components/Services/services';

import Pricing2 from '../../components/Pricing2/pricing2';
import GetInTouch2 from '../../components/Get in Touch2/get-in-touch2';

import About from '../../components/About/about';

import CarouselTest from '../../components/CarouselTest/carouseltest';
import CarouselTest2 from '../../components/CarouselTest2/carouseltest2';


import Animated  from '../../components/Animated/animated';

import Feature3 from '../../components/Feature3/feature3';

const Indexgower = () => {

    /*const [state, setState] = useState({
        pos: document.documentElement.scrollTop,
        imglight: true,
        navClass: ""
    })
    */

    //const [pos, setPos] = useState[{document.documentElement.scrollTop}];

    const [navClass, setNavclass] = useState("");
    const [imglight, setImglight] = useState(true);
    const [pos, setPos] = useState(0);
    const [scroll, setScroll] = useState({});

    /*const [scroll, setScroll] = useState({
        navClass: '',
        imglight: ''
    })*/
 
    /* componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    } */

    /* useEffect(() => {
        window.addEventListener("scroll", this.scrollNavigation, true);
        return () => {
          window.removeEventListener("scroll", this.scrollNavigation, true);
        }
     }, []) */

    // const [scrollNavigation, setScrollnavigation] = useState(true);

    const [title, setTitle] = useState("MI barra");

    const scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        console.log("scrollup", scrollup);

        console.log("pos: ", pos);

        if(scrollup > pos)
        {
            //setScroll({navClass : "darkheader"});
            setNavclass("darkheader");
            setImglight(false);
            setTitle("scroll mayor que pop");

        }
        else
        {
            //setScroll({navClass : "", imglight : true});
            setNavclass("");
            setImglight(true);
            setTitle("scroll < pos");
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        return () => {
          window.removeEventListener("scroll", scrollNavigation, true);
        }
     }, [])

     

    return (
        <React.Fragment>

            <NavbarPage navclass={navClass} imglight={imglight} />   
             

            
            <Section/>

            {/* <About /> */}

           
            <Features2/>

            <CarouselTest />

            <Feature3 />

            <CarouselTest2 />

            <GetStarted2 />

            <Services />

            {/* <Animated /> */}


            <Pricing2 />

            <GetInTouch2 />

            <Footer2 />

        </React.Fragment>   
    )
}

export default Indexgower;



/*

class Indexgower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "darkheader", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    */



    /*
    render() {
        return (
            <React.Fragment>

                
                <NavbarPage navclass={this.state.navClass} imglight={this.state.imglight} />

                
                <Section/>

  
                <Features2/>
                
                
                <GetStarted />

             
                <Services/>

              
                <Clients />

  
                 <OurTeam/>

               
                <Pricing />

        
                <GetInTouch/>

              
                <Footer/>

            </React.Fragment>
        );
    }
}

export default Indexgower;

*/