import React, { useState, Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

const Services = () => {

    const [services, setServices] = useState([
        { title : "Ventas automatizadas", icon : "mbri-refresh", description : "Esta forma de trabajar te ofrece unos claros beneficios operativos. Podrás nutrir leads y obtener conversiones mientras dedicas tu tiempo a tareas clave." },
            { title : "Puntos de venta ilimitados", icon : "mbri-map-pin", description : "El punto de venta se ha transformado en nuevos spots donde el route to market y los canales de venta se vuelven pilares en un plan meramente exitoso." },
            { title : "Estrategia de envios ", icon : "mbri-delivery", description : "Transportistas y métodos de envío según el tipo de cliente que tienes. Gastos de envío personalizados. Automatización del proceso de envío + emails de seguimiento." },
    ])

    const [services1, setServices1] = useState([
        { title : "Soporte y Seguridad", icon : "mbri-lock", description : "Asistencia a los usuarios de forma directa, Soporte técnico vía telefónica, vía chat, vía asistencia técnica personal, vía asistencia remota y otros." },
            { title : "Depósitos", icon : "mbri-responsive", description : "Control y optimización de los procesos del depósito, multiplicando su eficiencia y rentabilidad,de forma manual o incluso  automática. " },
            { title : "Fácil de personalizar", icon : "mbri-setting", description : "Podrás personalizar de forma rápida y sencilla cada sección acorde a tus necesidades. Nunca fue tan simple. Accede a todas las herramientas con un click." },
    ])

    return (
        <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Nuestros" title2="Servicios" />
                <Row className="pt-4 mt-4">
                    <ServiceBox services={services} />
                {/* <ServiceBox services={this.state.services} /> */}
                </Row>
                <Row>
                    <ServiceBox services={services1} />
                {/* <ServiceBox services={this.state.services1} /> */}
                </Row>
            </Container>
        </section>
            </React.Fragment>
    )
}


/*
class Services extends Component {
    state = {
        services : [
            { title : "Ventas automatizadas", icon : "mbri-code", description : "Esta forma de trabajar te ofrece unos claros beneficios operativos. Podrás nutrir leads y obtener conversiones mientras dedicas tu tiempo a tareas clave." },
            { title : "Puntos de venta ilimitados", icon : "mbri-features", description : "El punto de venta se ha transformado en nuevos spots donde el route to market y los canales de venta se vuelven pilares en un plan meramente exitoso." },
            { title : "Estrategia de envios ", icon : "mbri-growing-chart", description : "Transportistas y métodos de envío según el tipo de cliente que tienes. Gastos de envío personalizados. Automatización del proceso de envío + emails de seguimiento." },
        ],
        services1 : [
            { title : "Excelente soporte", icon : "mbri-photos", description : "Asistencia a los usuarios de forma directa, Soporte técnico vía telefónica, vía chat, vía asistencia técnica personal, vía asistencia remota y otros." },
            { title : "Depósitos", icon : "mbri-responsive", description : "Control y optimización de los procesos del depósito, multiplicando su eficiencia y rentabilidad,de forma manual o incluso  automática. " },
            { title : "Fácil de personalizar", icon : "mbri-download", description : "Podrás personalizar de forma rápida y sencilla cada sección acorde a tus necesidades. Nunca fue tan simple. Accede a todas las herramientas con un click." },
        ],
        
    }
    render() {
        return (
            <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Nuestros" title2="Servicios" />
                <Row className="pt-4 mt-4">
                <ServiceBox services={this.state.services} />
                </Row>
                <Row>
                <ServiceBox services={this.state.services1} />
                </Row>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}
*/

export default Services;