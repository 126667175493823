import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks2 from "./footer-links2";

const Footer2 = () => {
    return (
        <React.Fragment>
            <FooterLinks2 />
        </React.Fragment>
    )
}
 
export default Footer2;