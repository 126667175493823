import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/features1.png";
import img2 from "../../assets/images/features/img_envios.png";

import img3 from "../../assets/images/features/features3.png";


import FeatureBox from "../common/feature-box";

const Features2 = () => {
    return (
        <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="Nuestras" title2="Ventajas" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img1}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="TU NEGOCIO EN  CONTINUO CRECIEMIENTO"
                  desc="El tiempo lo vale, tus transacciones y acciones definirán el éxito de tu negocio"
                  link="#"
                />
              </Col>
            </Row>

            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="TU LOGISTICA DE FORMA PERSONALIZADA"
                  desc="Podrás organizar zonas y envíos de forma independiente según tus necesidades"
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img3}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="PUNTOS DE VENTA TOTALMENTE PERSONALIZADOS"
                  desc="Podrás elegir de forma práctica y agil cada punto de venta para tus operaciones"
                  link="#"
                />
              </Col>
            </Row>

            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="SISTEMA ÁGIL Y MODERNO, MUY FÁCIL DE PERSONALIZAR"
                  desc="Nunca un sistema te permitió personzalizar y configurar todo de una forma tan sencilla. Accesos inmediatos."
                  link="#contact"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>

        </Container>
        </section>
        </React.Fragment>
    )
}

export default Features2;