import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import BackgroundSlider from 'react-background-slider';

//Importing Modal
import ModalSection from '../../components/common/ModalSection';

//Import Images
// import image1 from "../../assets/images/bg.jpg";
import image1 from "../../assets/images/bg_bis.jpg";
import image3 from "../../assets/images/bg-3.jpg";

import imageBack from "../../assets/images/img_main.png";
import imageBack2 from "../../assets/images/img_main2bis.png";

import imgMain3 from '../../assets/images/img-main3.png';

import imgMain5 from '../../assets/images/img_main5_new.png';



const Section = () => {
    return (

        <React.Fragment>
        <BackgroundSlider
    images={[image1]}
    duration={3} transition={1}
/>
<section className="home-height-half" id="home">
    <div className="bg-overlay-gredient"></div>
    <div className="home-center">
        <div className="home-desc-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="12" className="text-center">
                        <h6 className="home-title text-white">Sabemos que el tiempo vale</h6>
                        <p className="pt-4 home-sub-title text-white mx-auto">Agilizá tus acciones. Automatizá tu gestión.</p>
                        
                    </Col>

                    <Col lg="12" className="text-center">
                        <div style={{ width: '100%', height: '450px'}}>

                       
                        <img
                    src={imgMain5}
                    alt="Gower Main Image"
                    className="img-fluid mx-auto d-block"
                  />
                        </div>
                    </Col>

                </Row>
                
                
            </Container>
        </div>
    </div>
</section>
    </React.Fragment>

    )
}

export default Section;