import React, { Component } from 'react';

const FooterLinks2 = () => {
    return (
        <React.Fragment>
        <div className="footer-alt bg-dark">
            <p className="copy-rights"> 2020 © Gower - Sistema ágil de gestión.</p>
        </div>
            </React.Fragment>
    )
}

export default FooterLinks2;