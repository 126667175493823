import React, { useState, Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox2 from './pricing-box2';



const Pricing2 = () => {

    const [pricing, setPricing] = useState([
        { id : 1, title : "Free / Demo", price : "0", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "50MB", support : "No", domain : "-", hiddenFees : "-" }
                
            },
            { id : 2, title : "Standard", price : "250", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "500MB", support : "Sí", domain : "5 Domain5", hiddenFees : "-" }
                
            },
            { id : 3, title : "Premium", price : "399", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "2GB", support : "Yes", domain : "10 Domain", hiddenFees : "No Hidden Fees" }
                
            },
    ])

    return (
        <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                    <SectionTitle title1="Nuestros" title2="Planes" />
                        <Row className="mt-4 pt-4">
                            
                                <PricingBox2 pricings={pricing} />
                            
                            
                        </Row>
                    </Container>
                </section>
            </React.Fragment> 
    )
}

/*
class Pricing2 extends Component {
    state = {
        pricings : [
            { id : 1, title : "Free", price : "0", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "50MB", support : "No", domain : "-", hiddenFees : "-" }
                
            },
            { id : 2, title : "Standard", price : "250", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "500MB", support : "Yes", domain : "5 Domain5", hiddenFees : "-" }
                
            },
            { id : 3, title : "Premium", price : "399", duration : "m",
                features : 
                    { bandwith : "1GB", onlinespace : "2GB", support : "Yes", domain : "10 Domain", hiddenFees : "No Hidden Fees" }
                
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                    <SectionTitle title1="Nuestros" title2="Planes" />
                        <Row className="mt-4 pt-4">
                            <PricingBox2 pricings={this.state.pricings} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

*/

export default Pricing2;